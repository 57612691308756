import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { DownloadExcel } from "react-excel-export";
import { useLocation } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DepositeHistory, url } from "../../../services/api_function";
import reactSelect from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";

const Deposit = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [fullcsvData, setfullcsvData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const pageSize = 100;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const response = await DepositeHistory(
          currentPage,
          { searchQuery: search },
          token
        );
        const { data, totalCount } = response;
        setFilteredData(data);
        const pages = Math.ceil(totalCount[0].totalCount / pageSize);
        setTotalPages(pages > 0 ? pages : 1);

        const ModifiedData = data.map((item) => {
          const { Name, user, amount, ratio, token, txHash, createdAt } = item;
          const Timestamp = moment(createdAt).format("DD-MM-YYYY HH:mm");
          const wyz =
            ratio == "10"
              ? ((amount * 0.1) / 20).toFixed(2)
              : ratio == "20"
              ? ((amount * 0.2) / 20).toFixed(2)
              : ratio == "30"
              ? ((amount * 0.3) / 20).toFixed(2)
              : ratio == "40"
              ? ((amount * 0.4) / 20).toFixed(2)
              : ratio == "50"
              ? ((amount * 0.5) / 20).toFixed(2)
              : ratio == "15" && token == "sUSDT-stUSDT"
              ? ((amount * 0.15) / 20).toFixed(2)
              : ratio == "20" && token == "sUSDT-stUSDT"
              ? ((amount * 0.2) / 20).toFixed(2)
              : ratio == "25" && token == "sUSDT-stUSDT"
              ? ((amount * 0.25) / 20).toFixed(2)
              : "0.00";

          const stUsdt =
            ratio == "10"
              ? (amount * 0.9).toFixed(2)
              : ratio == "20"
              ? (amount * 0.8).toFixed(2)
              : ratio == "30"
              ? (amount * 0.7).toFixed(2)
              : ratio == "40"
              ? (amount * 0.6).toFixed(2)
              : ratio == "50"
              ? (amount * 0.5).toFixed(2)
              : ratio == "15" && token == "sUSDT-stUSDT"
              ? (amount * 0.85).toFixed(2)
              : ratio == "20" && token == "sUSDT-stUSDT"
              ? (amount * 0.8).toFixed(2)
              : ratio == "25" && token == "sUSDT-stUSDT"
              ? (amount * 0.75).toFixed(2)
              : "0.00";
          return {
            Name: Name,
            user: user,
            wyz: wyz,
            stUsdt: stUsdt,
            total: amount,
            token: token,
            ratio: ratio,
            txHash: txHash,
            createdAt: Timestamp,
          };
        });
        setcsvData(ModifiedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    handleDownload();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const columns = [
    { label: "Name", key: "Name" },
    { label: "User", key: "user" },
    { label: "WYZ", key: "wyz" },
    { label: "Stusdt", key: "stUsdt" },
    { label: "Total", key: "total" },
    { label: "Token", key: "token" },
    { label: "Ratio", key: "ratio" },
    { label: "Transaction ID", key: "txHash" },
    { label: "Date&Time", key: "createdAt" },
  ];
  const handleDownload = async () => {
    try {
      const userDetails = localStorage.getItem("userDetails");
      const parsedDetails = JSON.parse(userDetails);
      const token = parsedDetails.token;
      const response = await axios.get(url + "/deposite-csv", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data?.ModifiedData;
      if (data) {
        setfullcsvData(data);
        setIsDataLoaded(true);
      } else {
        console.error("No data found.");
        setIsDataLoaded(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsDataLoaded(false);
    }
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Deposit Hisory
              </Card.Title>
              <CSVLink
                data={csvData}
                headers={columns}
                filename={"depositeStaking.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Page CSV
              </CSVLink>
              <CSVLink
                data={fullcsvData}
                headers={columns}
                filename={"depositefull.csv"}
                style={{
                  background: "white",
                  color: "black",
                  padding: "10px 20px",
                  textDecoration: "none",
                  borderRadius: "5px",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginRight: "40px",
                }}
              >
                Full CSV
              </CSVLink>
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>User</strong>
                    </th>

                    <th>
                      <strong> WYZ</strong>
                    </th>
                    <th>
                      <strong> stUSDT</strong>
                    </th>
                    {/* <th>
                      <strong> sUSDT</strong>
                    </th> */}
                    <th>
                      <strong> Total</strong>
                    </th>
                    <th>
                      <strong>Token</strong>
                    </th>
                    <th>
                      <strong>Ratio</strong>
                    </th>
                    <th>
                      <strong>Transaction Id</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((data, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{data?.Name}</td>
                        <td>{data?.user}</td>

                        <td>
                          {data.ratio == "10"
                            ? ((data.amount * 0.1) / 20).toFixed(2)
                            : data.ratio == "20"
                            ? ((data.amount * 0.2) / 20).toFixed(2)
                            : data.ratio == "30"
                            ? ((data.amount * 0.3) / 20).toFixed(2)
                            : data.ratio == "40"
                            ? ((data.amount * 0.4) / 20).toFixed(2)
                            : data.ratio == "50"
                            ? ((data.amount * 0.5) / 20).toFixed(2)
                            : data.ratio == "15" && data.token == "sUSDT-stUSDT"
                            ? ((data.amount * 0.15) / 20).toFixed(2)
                            : data.ratio == "20" && data.token == "sUSDT-stUSDT"
                            ? ((data.amount * 0.2) / 20).toFixed(2)
                            : data.ratio == "25" && data.token == "sUSDT-stUSDT"
                            ? ((data.amount * 0.25) / 20).toFixed(2)
                            : "0.00"}
                        </td>
                        <td>
                          {" "}
                          {data.ratio == "10"
                            ? (data.amount * 0.9).toFixed(2)
                            : data.ratio == "20"
                            ? (data.amount * 0.8).toFixed(2)
                            : data.ratio == "30"
                            ? (data.amount * 0.7).toFixed(2)
                            : data.ratio == "40"
                            ? (data.amount * 0.6).toFixed(2)
                            : data.ratio == "50"
                            ? (data.amount * 0.5).toFixed(2)
                            : data.ratio == "15" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.85).toFixed(2)
                            : data.ratio == "20" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.8).toFixed(2)
                            : data.ratio == "25" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.75).toFixed(2)
                            : "0.00"}
                        </td>
                        {/* <td>
                          {data.ratio == "15" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.15).toFixed(2)
                            : data.ratio == "20" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.2).toFixed(2)
                            : data.ratio == "25" && data.token == "sUSDT-stUSDT"
                            ? (data.amount * 0.25).toFixed(2)
                            : "0.00"}
                        </td> */}
                        <td>{Number(data?.amount).toFixed(2)}</td>
                        <td>{data?.token}</td>
                        <td>{data?.ratio}</td>
                        <td>
                          <a
                            href={`https://wyzthscan.org/tx/${data?.txHash}`}
                            className="text-white"
                            target="_blank"
                          >
                            {data?.txHash?.slice(0, 5)}... {data?.txHash?.slice(-5)}
                          </a>
                        </td>
                        <td>{formatTimestamp(data?.createdAt)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Deposit;
